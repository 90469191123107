<template>
  <v-dialog v-model="dialog" persistent hide-overlay scrollable width="550">
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip
        :disabled="!alertData.result_status"
        top
        color="rgba(0, 0, 0, 0.9)"
      >
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            :color="alertData.result_status ? 'primary' : ''"
          >
            mdi-comment-search
          </v-icon>
        </template>

        {{ $t(`result.${alertData.result_status}`) }}
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title> Résultat d'analyse </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-4" style="height: 500px">
        <v-row>
          <v-col cols="12">
            <div class="mt-2 font-weight-regular font-italic">
              {{ alertData.rule.name }}
            </div>
          </v-col>
          <v-col>
            <v-icon left>mdi-calendar-search</v-icon>
            {{ alertData.date | formatDate }}
          </v-col>
          <v-col class="text-right">
            <v-icon left>mdi-account-search</v-icon>
            {{ alertData.key }}
          </v-col>
        </v-row>

        <v-divider class="mt-4 mb-n3" />

        <div v-for="(status, i) in Object.keys(analysisStatuses)" :key="i">
          <div
            :class="{
              'text--disabled':
                alertData.relevant_status &&
                alertData.relevant_status != status,
            }"
            class="mt-3 subtitle-1 font-weight-medium"
          >
            {{ $t(`result.${status}`) }}
          </div>

          <v-checkbox
            v-model="selected"
            v-for="(result, j) in analysisStatuses[status]"
            :key="i + j"
            :label="$t(`result.${result}`)"
            :value="`${status}:${result}`"
            :disabled="
              alertData.relevant_status && alertData.relevant_status != status
            "
            hide-details
            on-icon="mdi-checkbox-intermediate"
            off-icon="mdi-checkbox-blank-outline"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" color="error" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn :disabled="!selected" @click="updateResult()" color="primary">
          {{ $t("send") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    alertData: {
      type: Object
    }
  },

  data() {
    return {
      dialog: false,
      alertDataCopy: Object.assign(this.alertData)
    };
  },

  computed: {
    ...mapState("analysis", ["analysisStatuses"]),

    selected: {
      get() {
        return `${this.alertData.relevant_status}:${this.alertData.result_status}`;
      },
      set(v) {
        this.alertData.relevant_status = v ? v.split(":")[0] : this.alertData.relevant_status;
        this.alertData.result_status = v && v.split(":")[1];
      }
    }
  },

  methods: {
    updateResult() {
      this.$store.dispatch("alerts/updateAlertRelevance", {
        alertData: this.alertData,
        relevantStatus: this.alertData.relevant_status,
        resultStatus: this.alertData.result_status
      });

      this.dialog = false;
    },

    closeDialog() {
      this.alertData.relevant_status = this.alertDataCopy.relevant_status;
      this.alertData.result_status = this.alertDataCopy.result_status;
      this.dialog = false;
    }
  }
};
</script>
